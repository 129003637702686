const mappings = {
    'af-ZA': {
        'synonyms': {
            'af': null,
            'afr': null,
            'af-za': null,
            'af_za': null,
            'afrikaans': null
        },
        'label': 'Afrikaans'
    },
    'ar': {
        'synonyms': {
            'ar': null,
            'ara': null,
            'ar-ar': null,
            'ar_ar': null,
            'arabic': null
        },
        'label': 'Arabic'
    },
    'bg-BG': {
        'synonyms': {
            'bg': null,
            'bul': null,
            'bg-bg': null,
            'bg_bg': null,
            'bulgarian': null
        },
        'label': 'Bulgarian'
    },
    'ca-ES': {
        'synonyms': {
            'ca': null,
            'cat': null,
            'ca-fr': null,
            'ca_fr': null,
            'ca-es': null,
            'ca_es': null,
            'catalan': null
        },
        'label': 'Catalan'
    },
    'zh-CN': {
        'synonyms': {
            'zh-cn': null,
            'zh_cn': null
        },
        'label': 'Chinese (PRC)'
    },
    'zh-TW': {
        'synonyms': {
            'zh': null,
            'chi': null,
            'zh-tw': null,
            'zh_tw': null,
            'chinese': null
        },
        'label': 'Chinese (Taiwan)'
    },
    'cs-CZ': {
        'synonyms': {
            'cs': null,
            'cz': null,
            'cze': null,
            'cs-cz': null,
            'cs_cz': null,
            'czech': null
        },
        'label': 'Czech'
    },
    'da-DK': {
        'synonyms': {
            'da': null,
            'dan': null,
            'da-dk': null,
            'da_dk': null,
            'danish': null
        },
        'label': 'Danish'
    },
    'nl-BE': {
        'synonyms': {
            'nl-be': null,
            'nl_be': null,
            'dutch belgium': null
        },
        'label': 'Dutch (Belgium)'
    },
    'nl-NL': {
        'synonyms': {
            'nl': null,
            'dut': null,
            'nl-nl': null,
            'nl_nl': null,
            'dutch': null
        },
        'label': 'Dutch'
    },
    'en-AU': {
        'synonyms': {
            'aue': null,
            'aus': null,
            'en-au': null,
            'en_au': null,
            'english au': null
        },
        'label': 'English (Australia)'
    },
    'en-GB': {
        'synonyms': {
            'en': null,
            'eng': null,
            'en-gb': null,
            'en_gb': null,
            'english': null,
            'default': null
        },
        'label': 'English (Great Britain)'
    },
    'en-NZ': {
        'synonyms': {
            'en-nz': null,
            'en_nz': null,
            'english new zealand': null
        },
        'label': 'English (New Zealand)'
    },
    'en-US': {
        'synonyms': {
            'us': null,
            'ame': null,
            'en-us': null,
            'en_us': null,
            'english us': null
        },
        'label': 'English (US)'
    },
    'et-EE': {
        'synonyms': {
            'et-ee': null,
            'et_ee': null,
            'estonian': null
        },
        'label': 'Estonian'
    },
    'eu-ES': {
        'synonyms': {
            'eu-es': null,
            'eu_es': null,
            'basque': null
        },
        'label': 'Basque (Spain)'
    },
    'fi-FI': {
        'synonyms': {
            'fi': null,
            'fin': null,
            'fi-fi': null,
            'fi_fi': null,
            'finnish': null
        },
        'label': 'Finnish'
    },
    'fr-CA': {
        'synonyms': {
            'fr-ca': null,
            'fr_ca': null,
            'french canadian': null
        },
        'label': 'French (Canadian)'
    },
    'fr-FR': {
        'synonyms': {
            'fr': null,
            'fre': null,
            'fr-fr': null,
            'fr_fr': null,
            'french': null
        },
        'label': 'French'
    },
    'de-DE': {
        'synonyms': {
            'de': null,
            'ger': null,
            'de-de': null,
            'de_de': null,
            'german': null
        },
        'label': 'German'
    },
    'el-GR': {
        'synonyms': {
            'el': null,
            'gre': null,
            'el-gr': null,
            'el_gr': null,
            'greek': null
        },
        'label': 'Greek'
    },
    'gl-ES': {
        'synonyms': {
            'gl-es': null,
            'gl_es': null,
            'galician': null
        },
        'label': 'Galician (Spain)'
    },
    'he-IL': {
        'synonyms': {
            'he-il': null,
            'he_il': null,
            'hebrew': null
        },
        'label': 'Hebrew (Israel)'
    },
    'hr-HR': {
        'synonyms': {
            'hr': null,
            'hr-hr': null,
            'hr_hr': null,
            'croatian': null
        },
        'label': 'Croatian'
    },
    'hu-HU': {
        'synonyms': {
            'hu': null,
            'hun': null,
            'hu-hu': null,
            'hu_hu': null,
            'hungarian': null
        },
        'label': 'Hungarian'
    },
    'id-ID': {
        'synonyms': {
            'id': null,
            'ind': null,
            'id-id': null,
            'id_id': null,
            'indonesian': null
        },
        'label': 'Indonesian'
    },
    'it-IT': {
        'synonyms': {
            'it': null,
            'ita': null,
            'it-it': null,
            'it_it': null,
            'italian': null
        },
        'label': 'Italian'
    },
    'kk-KZ': {
        'synonyms': {
            'kk-kz': null,
            'kk_kz': null,
            'kazakh': null
        },
        'label': 'Kazakh (Kazakhstan)'
    },
    'lv-LV': {
        'synonyms': {
            'lt': null,
            'lv-lv': null,
            'lv_lv': null,
            'latvian': null
        },
        'label': 'Latvian'
    },
    'lt-LT': {
        'synonyms': {
            'lt': null,
            'lit': null,
            'lt-lt': null,
            'lt_lt': null,
            'lithuanian': null
        },
        'label': 'Lithuanian'
    },
    'ms-MY': {
        'synonyms': {
            'ms': null,
            'mal': null,
            'ms-my': null,
            'ms_my': null,
            'malay': null
        },
        'label': 'Malay'
    },
    'no-NO': {
        'synonyms': {
            'no': null,
            'nb': null,
            'nor': null,
            'nb-no': null,
            'nb_no': null,
            'no-no': null,
            'no_no': null,
            'norwegian': null
        },
        'label': 'Norwegian'
    },
    'pl-PL': {
        'synonyms': {
            'pl': null,
            'pol': null,
            'pl-pl': null,
            'pl_pl': null,
            'polish': null
        },
        'label': 'Polish'
    },
    'ro-RO': {
        'synonyms': {
            'ro-ro': null,
            'ro_ro': null,
            'romanian': null
        },
        'label': 'Romanian'
    },
    'ru-RU': {
        'synonyms': {
            'ru': null,
            'rus': null,
            'ru-ru': null,
            'ru_ru': null,
            'russian': null
        },
        'label': 'Russian'
    },
    'sr-RS': {
        'synonyms': {
            'sr-rs': null,
            'sr_rs': null,
            'serbian': null
        },
        'label': 'Serbian'
    },
    'sk-SK': {
        'synonyms': {
            'sk': null,
            'slo': null,
            'sk-sk': null,
            'sk_sk': null,
            'slovak': null
        },
        'label': 'Slovak'
    },
    'sl-SI': {
        'synonyms': {
            'sl': null,
            'slv': null,
            'sl-si': null,
            'sl_si': null,
            'slovenian': null
        },
        'label': 'Slovenian'
    },
    'es-ES': {
        'synonyms': {
            'es': null,
            'spa': null,
            'es-es': null,
            'es_es': null,
            'spanish': null
        },
        'label': 'Spanish'
    },
    'es-419': {
        'synonyms': {
            'es-419': null,
            'es_419': null,
            'latin america spanish': null
        },
        'label': 'Latin American Spanish'
    },
    'sv-SE': {
        'synonyms': {
            'sv': null,
            'swe': null,
            'sv-se': null,
            'sv_se': null,
            'swedish': null
        },
        'label': 'Swedish'
    },
    'th-TH': {
        'synonyms': {
            'th': null,
            'tha': null,
            'th-th': null,
            'th_th': null,
            'thai': null
        },
        'label': 'Thai'
    },
    'tr-TR': {
        'synonyms': {
            'tr': null,
            'tur': null,
            'tr-tr': null,
            'tr_tr': null,
            'turkish': null
        },
        'label': 'Turkish'
    },
    'uk-UA': {
        'synonyms': {
            'uk-ua': null,
            'uk_ua': null,
            'ukrainian': null
        },
        'label': 'Ukrainian'
    },
    'vi-VN': {
        'synonyms': {
            'vi-vn': null,
            'vi_vn': null,
            'vietnamese': null
        },
        'label': 'Vietnamese (Viet Nam)'
    },
    'NGT': {
        'synonyms': {
            'ngt': null
        },
        'label': 'Neutral Ground Truth'
    },
    'NGT-Latn': {
        'synonyms': {
            'ngt-latn': null
        },
        'label': 'Neutral Ground Truth - Latin exonyms'
    },
    'en-CA': {
        'synonyms': {
            'en-ca': null,
            'en_ca': null,
            'english canadian': null
        },
        'label': 'English (Canada)'
    },
    'ko-KR': {
        'synonyms': {
            'ko': null,
            'kor': null,
            'ko-kr': null,
            'ko_kr': null,
            'korean': null
        },
        'label': 'Korean'
    },
    'nb-NO': {
        'synonyms': {
            'nb': null,
            'nor': null,
            'nb-no': null,
            'nb_no': null,
            'norwegian': null
        },
        'label': 'Norwegian'
    },
    'pt-BR': {
        'synonyms': {
            'br': null,
            'pob': null,
            'pt-br': null,
            'pt_br': null,
            'portuguese br': null
        },
        'label': 'Portuguese (BR)'
    },
    'pt-PT': {
        'synonyms': {
            'pt': null,
            'por': null,
            'pt-pt': null,
            'pt_pt': null,
            'portuguese': null
        },
        'label': 'Portuguese'
    },
    'ru-Latn-RU': {
        'synonyms': {
            'ru-latn-ru': null,
            'ru_latn_ru': null
        },
        'label': 'Russian (Latin)'
    },
    'ru-Cyrl-RU': {
        'synonyms': {
            'ru-cyrl-ru': null,
            'ru_cyrl_ru': null
        },
        'label': 'Russian (Cyrlic)'
    },
    'es-MX': {
        'synonyms': {
            'mx': null,
            'spm': null,
            'es-mx': null,
            'es_mx': null,
            'spanish mx': null
        },
        'label': 'Spanish (Mexico)'
    },
    'defaultValue': {
        'value': 'en-GB',
        'label': 'English (Great Britain)'
    }
};

export const traffic = {
    'ar': mappings.ar,
    'ca': mappings['ca-ES'],
    'cs': mappings['cs-CZ'],
    'da': mappings['da-DK'],
    'de': mappings['de-DE'],
    'el': mappings['el-GR'],
    'en': {
        'synonyms': {
            'en': null,
            'eng': null,
            'en-gb': null,
            'en_gb': null,
            'english': null,
            'us': null,
            'ame': null,
            'en-us': null,
            'en_us': null,
            'english us': null,
            'default': null
        },
        'label': 'English'
    },
    'es': {
        'synonyms': {
            'es': null,
            'spa': null,
            'es-es': null,
            'es_es': null,
            'spanish': null,
            'mx': null,
            'spm': null,
            'es-mx': null,
            'es_mx': null,
            'spanish mx': null
        },
        'label': 'Spanish'
    },
    'et': mappings['et-EE'],
    'fi': mappings['fi-FI'],
    'fr': mappings['fr-FR'],
    'he': mappings['he-IL'],
    'hu': mappings['hu-HU'],
    'id': mappings['id-ID'],
    'it': mappings['it-IT'],
    'lt': mappings['lt-LT'],
    'lv': mappings['lv-LV'],
    'nb': mappings['nb-NO'],
    'nl': mappings['nl-NL'],
    'no': mappings['no-NO'],
    'pl': mappings['pl-PL'],
    'pt': {
        'synonyms': {
            'br': null,
            'por': null,
            'pt-br': null,
            'pt_br': null,
            'portuguese': null,
            'pt': null,
            'pob': null,
            'pt-pt': null,
            'pt_pt': null,
            'portuguese br': null
        },
        'label': 'Portuguese'
    },
    'ro': mappings['ro-RO'],
    'ru': mappings['ru-RU'],
    'sk': mappings['sk-SK'],
    'sv': mappings['sv-SE'],
    'th': mappings['th-TH'],
    'tr': mappings['tr-TR'],
    'zh': mappings['zh-TW'],
    'defaultValue': {
        'value': 'en',
        'label': 'English (Great Britain)'
    }
};

export const routing = {
    'ar': mappings.ar,
    'af-ZA': mappings['af-ZA'],
    'bg-BG': mappings['bg-BG'],
    'zh-TW': mappings['zh-TW'],
    'cs-CZ': mappings['cs-CZ'],
    'da-DK': mappings['da-DK'],
    'nl-NL': mappings['nl-NL'],
    'en-GB': mappings['en-GB'],
    'en-US': mappings['en-US'],
    'fi-FI': mappings['fi-FI'],
    'fr-FR': mappings['fr-FR'],
    'de-DE': mappings['de-DE'],
    'el-GR': mappings['el-GR'],
    'hu-HU': mappings['hu-HU'],
    'id-ID': mappings['id-ID'],
    'it-IT': mappings['it-IT'],
    'ko-KR': mappings['ko-KR'],
    'lt-LT': mappings['lt-LT'],
    'ms-MY': mappings['ms-MY'],
    'nb-NO': mappings['nb-NO'],
    'pl-PL': mappings['pl-PL'],
    'pt-BR': mappings['pt-BR'],
    'pt-PT': mappings['pt-PT'],
    'ru-RU': mappings['ru-RU'],
    'sk-SK': mappings['sk-SK'],
    'sl-SI': mappings['sl-SI'],
    'es-ES': mappings['es-ES'],
    'es-MX': mappings['es-MX'],
    'sv-SE': mappings['sv-SE'],
    'th-TH': mappings['th-TH'],
    'tr-TR': mappings['tr-TR'],
    'defaultValue': mappings.defaultValue
};

export const search = {
    'NGT': mappings.NGT,
    'NGT-Latn': mappings['NGT-Latn'],
    'af-ZA': mappings['af-ZA'],
    'ar': mappings.ar,
    'eu-ES': mappings['eu-ES'],
    'bg-BG': mappings['bg-BG'],
    'ca-ES': mappings['ca-ES'],
    'zh-CN': mappings['zh-CN'],
    'zh-TW': mappings['zh-TW'],
    'cs-CZ': mappings['cs-CZ'],
    'da-DK': mappings['da-DK'],
    'nl-BE': mappings['nl-BE'],
    'nl-NL': mappings['nl-NL'],
    'en-AU': mappings['en-AU'],
    'en-NZ': mappings['en-NZ'],
    'en-GB': mappings['en-GB'],
    'en-US': mappings['en-US'],
    'et-EE': mappings['et-EE'],
    'fi-FI': mappings['fi-FI'],
    'fr-CA': mappings['fr-CA'],
    'fr-FR': mappings['fr-FR'],
    'gl-ES': mappings['gl-ES'],
    'de-DE': mappings['de-DE'],
    'el-GR': mappings['el-GR'],
    'hr-HR': mappings['hr-HR'],
    'he-IL': mappings['he-IL'],
    'hu-HU': mappings['hu-HU'],
    'id-ID': mappings['id-ID'],
    'it-IT': mappings['it-IT'],
    'kk-KZ': mappings['kk-KZ'],
    'lv-LV': mappings['lv-LV'],
    'lt-LT': mappings['lt-LT'],
    'ms-MY': mappings['ms-MY'],
    'no-NO': mappings['no-NO'],
    'nb-NO': mappings['nb-NO'],
    'pl-PL': mappings['pl-PL'],
    'pt-BR': mappings['pt-BR'],
    'pt-PT': mappings['pt-PT'],
    'ro-RO': mappings['ro-RO'],
    'ru-RU': mappings['ru-RU'],
    'ru-Latn-RU': mappings['ru-Latn-RU'],
    'ru-Cyrl-RU': mappings['ru-Cyrl-RU'],
    'sr-RS': mappings['sr-RS'],
    'sk-SK': mappings['sk-SK'],
    'sl-SI': mappings['sl-SI'],
    'es-ES': mappings['es-ES'],
    'es-419': mappings['es-419'],
    'sv-SE': mappings['sv-SE'],
    'th-TH': mappings['th-TH'],
    'tr-TR': mappings['tr-TR'],
    'uk-UA': mappings['uk-UA'],
    'vi-VN': mappings['vi-VN'],
    'defaultValue': mappings.defaultValue
};

export const maps = {
    'NGT': mappings.NGT,
    'NGT-Latn': mappings['NGT-Latn'],
    'ar': mappings.ar,
    'bg-BG': mappings['bg-BG'],
    'zh-TW': mappings['zh-TW'],
    'cs-CZ': mappings['cs-CZ'],
    'da-DK': mappings['da-DK'],
    'nl-NL': mappings['nl-NL'],
    'en-AU': mappings['en-AU'],
    'en-CA': mappings['en-CA'],
    'en-GB': mappings['en-GB'],
    'en-NZ': mappings['en-NZ'],
    'en-US': mappings['en-US'],
    'fi-FI': mappings['fi-FI'],
    'fr-FR': mappings['fr-FR'],
    'de-DE': mappings['de-DE'],
    'el-GR': mappings['el-GR'],
    'hu-HU': mappings['hu-HU'],
    'id-ID': mappings['id-ID'],
    'it-IT': mappings['it-IT'],
    'ko-KR': mappings['ko-KR'],
    'lt-LT': mappings['lt-LT'],
    'ms-MY': mappings['ms-MY'],
    'nb-NO': mappings['nb-NO'],
    'pl-PL': mappings['pl-PL'],
    'pt-BR': mappings['pt-BR'],
    'pt-PT': mappings['pt-PT'],
    'ru-RU': mappings['ru-RU'],
    'ru-Latn-RU': mappings['ru-Latn-RU'],
    'ru-Cyrl-RU': mappings['ru-Cyrl-RU'],
    'sk-SK': mappings['sk-SK'],
    'sl-SI': mappings['sl-SI'],
    'es-ES': mappings['es-ES'],
    'es-MX': mappings['es-MX'],
    'sv-SE': mappings['sv-SE'],
    'th-TH': mappings['th-TH'],
    'tr-TR': mappings['tr-TR'],
    'defaultValue': mappings.defaultValue
};

export const defaultLanguage = 'en-GB';
